@font-face {
  font-family: 'Bree Serif';
  src: url("/assets/fonts/BreeSerif-Regular.ttf");
}

@font-face {
  font-family:'Bree Serif Lt';
  src: url("/assets/fonts/BreeSerif-Light.woff");
}

.App {
  position: absolute;
  text-align: center;
  font-family: 'Bree Serif','Helvetica Neue',Helvetica,Arial,sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857143;
  width: 100%;
  height: 100%;
  overflow: scroll;
}
