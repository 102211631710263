.header {
  width: 100%;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .logo {
    margin-top: 20px;
    width: 160px;
    height: auto;
  }
  .imageBox {
    margin: 15px 10%;

    .headerImage {
      width: 100%;
      height: auto;
    }
  }

  .title {
    margin: 20px;


    a {
      text-decoration: none;
      color: rgb(0, 0, 0);
      display: flex;
      flex-direction: row;
      align-items: center;

      .logo {
        width: 80px;
        height: auto;
        margin-right: 20px;
      }

      .logoText {
        font-size: 25px;
      }
    }
  }

  .nav {
    display: flex;
    width: 320px;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 20px;
    .navItem {
      text-decoration: none;
      color: rgb(118, 118, 118);

      &.active {
        color: rgb(0, 0, 0);
      }
    }
  }

  @media (min-width: 1000px) {
    .nav {
      width: 500px
    }
  }
}
