.footer {
  color: #512c1e;

  .footerDisclaimer {
    background-color: #ede9e8;
    padding: 30px;

    .footerList {
      display: flex;
      flex-direction: column;

      .footerHeading {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        height: 19px;
        line-height: 19.8px;

      }

      .footerLinks {
        margin: 0;
        padding: 0;
        list-style: none none;

        a {
          text-decoration: none;
          color: rgb(55, 55, 55);
        }
      };

      .footerLink {
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
      }
    }
  }

  .footerCopyright {
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;

    .footerLogo {
      width: 60px;
      height: auto;
    }

  }
}
