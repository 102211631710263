.homePage {
  width: 100%;
  display: flex;
  flex-direction: column;

  a{
    color: #b92679;
    text-decoration: none;
  }

  .banner {
    display: flex;
    flex-direction: row-reverse;
    margin: 15px 40px;
    color: #ffffff;
    background-color: #b92679;
    justify-content: space-around;

    li {
      margin-bottom: 10px;
    }

    .bannerImg {
      display: flex;
      width: 150px;
      min-width: 150px;
      margin-right: -30px;
      margin-top: -30px;

      img {
        width: 150px;
        height: 100%;
        object-fit: contain;
        object-position: bottom;
      }
    }
    .bannerText {
      font-size: 0.9em;
      margin-left: 30px;
      text-align: left;
      align-self: center;

      ol { padding-left: 0.2em}

      a {
        color: #ffffff;
        text-decoration: underline;
      }
    }
  }

  .intro {
    background-size: cover;
    background-position: top;
    width: 100%;

    .blur {
      display: flex;
      flex-direction: row;
      background: rgba(255, 255, 255, 0.2);
      backdrop-filter: blur(5px);

      align-items: center;
      justify-content: center;
    }

    .introText {
      width: 80%;
      padding: 20px 0;
    }
  }

  .title {
    text-align: left;
    padding: 0 40px;
    font-size: 20px;
  }

  @media (min-width: 700px) {
    .title {
      font-size: 30px;
    }
  }

  .descriptionContainer {

    display: flex;
    flex-direction: row;
    padding: 15px 40px;
    background-color: #ede9e8;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    .description {
      flex: 0 0 100%;
      text-align: left;

      .descriptionTitle {
        text-align: left;
        font-weight: 400;
        font-size: 18px;
        margin-bottom: 10px;
      }

      .descriptionText {
        font-size: 15px;
      }
    }

    .appContainer {
      flex: 0 0 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .order {
        width: 100px;
        margin: 10px;
      }

      .appTitle {
        color: #b92679;
        font-weight: 300;
      }
      .appImages {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex: 0 0 60%;
        padding: 10px 10px 0 10px;

        img {
          width: 120px;
          max-width: 120px;
          margin: 8px;
        }
      }
    }

    @media (min-width: 1200px) {
      .description {
        flex: 0 0 60%;
      }
      .appContainer {
        flex: 0 0 40%;
      }
    }
  }


  .detailsContainer {
    display: flex;
    flex-direction: column;
    padding: 0 40px;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;

    .detailsTitle {
      text-align: left;
      font-weight: 400;
      font-size: 18px;
      margin-top: 20px;
      margin-bottom: 10px;
    }
    .detailsText {
      flex: 0 0 100%;
      text-align: left;
      font-size: 15px;

      .videoContainer {
        display: flex;
        flex-direction: column;

        align-items: center;

        img {
          width: 240px;
        }
      }
    }

    .buttonLink {
      align-self: center;
      margin: 20px;
    }

    button {
      align-self: center;
      background-image: none;
      background: #81bc00;
      border: 1px solid #81bc00;
      color: #fff;
      cursor: pointer;
      display: inline-block;
      font-family: 'Bree Serif','Helvetica Neue',Helvetica,Arial,sans-serif;
      font-weight: 400;
      padding: 7px 15px;
      font-size: 1.4rem;
      box-sizing: border-box;
      vertical-align: middle;
      text-shadow: 0 -1px 0 rgba(81, 44, 30, 40%);
      box-shadow: 0 2px 4px rgba(81, 44, 30, 30%);
      border-radius: 3px;
      width: 200px;
    }
  }


  @media (min-width: 700px) {
    .videoContainer {
      flex-direction: row !important;
    }
  }

  .games {
    background: #b92679;
    padding: 20px 40px;
    text-align: left;
    color: #ffffff;

    a {
      text-decoration: underline;
      color: #fff9fc;
      font-weight: 200;
    }

    .gamesTitle {
      text-align: center;
      font-weight: 400;
      font-size: 28px;
      margin-bottom: 10px;
    }

    .gamesOverview {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .categoryItem {
        flex: 0 0 22%;
        margin: 12px;

        .gamesList {
          margin: 0;
          padding: 0;
          list-style: none none;

          a {
            text-decoration: none;
          }
        }
      }
    }
  }

  .features {
    background: #cb007c;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 40px;

    .title {
      text-align: center;
      font-weight: 400;
      font-size: 28px;
      margin-bottom: 10px;
    }

    .featureList {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      .feature {
        flex: 1 0 21%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .featureIcon {
          img {
            height: auto;
            width: 80px;
          }
        }
      }
    }
  }

  .footer {
    margin-top: auto;
  }

  @media (min-width: 700px) {
    .detailsContainer {
    }
  }

  @media (min-width: 800px) {
    .banner {
      .bannerText {
        font-size: 1.20em;
      }

    }
  }

  @media (min-width: 1100px) {
    .banner {
      margin: 15px 250px;
      flex-direction: row;

      .bannerText {
        font-size: 1.20em;

        li {
          margin-bottom: 15px;
        }
      }

      .bannerImg {
        margin-left: -40px;
        margin-top: -50px;
        width: 160px;
        min-width: 160px;
        img {
          width: 160px;
        }
      }
    }
    .descriptionContainer {
      padding: 15px 250px;
    }

    .detailsContainer {
      padding: 0 250px;
    }

    .title {
      padding: 0 250px;
    }

    .games {
      padding: 20px 250px;
    }
    .features {
      padding: 20px 250px;
    }
  }
  @media (min-width: 1500px) {
    .banner {
      margin: 15px 400px;
      flex-direction: row;

      .bannerText {
        font-size: 1.20em;

        li {
          margin-bottom: 15px;
        }
      }

      .bannerImg {
        margin-left: -40px;
        margin-top: -50px;
        width: 160px;
        min-width: 160px;
        img {
          width: 160px;
        }
      }
    }
    .descriptionContainer {
      padding: 15px 400px;
    }

    .detailsContainer {
      padding: 0 400px;
    }

    .title {
      padding: 0 400px;
    }

    .games {
      padding: 20px 400px;
    }
    .features {
      padding: 20px 400px;
    }
  }
}
