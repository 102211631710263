.faqPage {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .faqContainer {
    text-align: left;
    padding: 20px 40px;

    .faqTitle {
      font-weight: 400;
      font-size: 28px;
      margin-bottom: 10px;
    }

    .accordion__button {
      width: auto;
      color: #b92679;
    }
  }

  .footer {
    margin-top: auto;
  }

  @media (min-width: 1100px) {
    .faqContainer {
      padding: 20px 250px;
    }
  }

  @media (min-width: 1500px) {
    .faqContainer {
      padding: 20px 400px;
    }
  }
}
