.summaryPage {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .summaryContainer {
    text-align: center;
    display: flex;
    flex-direction: column;
    .summaryTitle {
      padding: 20px 40px;
      font-weight: 400;
      font-size: 28px;
      margin-bottom: 10px;
    }

    .summaryText {
      padding: 0 40px;
    }

    .loadingSpinner {
      align-self: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 40px;

      .loadingText {
        font-size: 13px;
      }

      .lds-roller {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
      }
      .lds-roller div {
        animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        transform-origin: 40px 40px;
      }
      .lds-roller div:after {
        content: " ";
        display: block;
        position: absolute;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: #cb007c;
        margin: -4px 0 0 -4px;
      }
      .lds-roller div:nth-child(1) {
        animation-delay: -0.036s;
      }
      .lds-roller div:nth-child(1):after {
        top: 63px;
        left: 63px;
      }
      .lds-roller div:nth-child(2) {
        animation-delay: -0.072s;
      }
      .lds-roller div:nth-child(2):after {
        top: 68px;
        left: 56px;
      }
      .lds-roller div:nth-child(3) {
        animation-delay: -0.108s;
      }
      .lds-roller div:nth-child(3):after {
        top: 71px;
        left: 48px;
      }
      .lds-roller div:nth-child(4) {
        animation-delay: -0.144s;
      }
      .lds-roller div:nth-child(4):after {
        top: 72px;
        left: 40px;
      }
      .lds-roller div:nth-child(5) {
        animation-delay: -0.18s;
      }
      .lds-roller div:nth-child(5):after {
        top: 71px;
        left: 32px;
      }
      .lds-roller div:nth-child(6) {
        animation-delay: -0.216s;
      }
      .lds-roller div:nth-child(6):after {
        top: 68px;
        left: 24px;
      }
      .lds-roller div:nth-child(7) {
        animation-delay: -0.252s;
      }
      .lds-roller div:nth-child(7):after {
        top: 63px;
        left: 17px;
      }
      .lds-roller div:nth-child(8) {
        animation-delay: -0.288s;
      }
      .lds-roller div:nth-child(8):after {
        top: 56px;
        left: 12px;
      }
      @keyframes lds-roller {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }

    .loginData {
      margin-top: 20px;
      padding: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #ede9e8;
      color: #000000;

      .title {
        margin-bottom: 9px;
        font-size: 14pt;
      }
      .QR {
        width: 190px;
        margin: 30px;
      }

      .credentials {
        text-align: left;
      }

      .validity {
        margin: 15px 0 5px;
        font-size: 13pt;
        background-color: #FFFFFF;
        color: #000000;
        padding: 10px;
        .validUntilDate {
          text-align: center;
          font-weight: bold;
          font-size: 12pt;
          margin-top: 12pt;
        }
      }

      .service {
        margin: 20px 0 0;
        font-size: 13px;

        a {
          color: red;
        }
      }
    }

    .orderId {
      padding: 5px 40px;
      font-size: 13px;
    }

    .downloadCodes {
      display: flex;
      flex-direction: column;
      Button {
        margin: 5px 0;
      }
    }

    .appContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      align-self: center;
      margin-top: 30px;
      width: 330px;

      .appImages {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
        margin-bottom: 40px;

        .appIcon {
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 100px;
            height: auto;
            margin-right: 20px;
          }
        }
      }

      .appDownloads {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
          width: 200px;
          height: auto;
          margin: 0 5px 10px 5px;
        }
      }
    }
  }

  .footer {
    margin-top: auto;
  }

  @media (min-width: 1000px) {
    .QR {
      width: 250px !important;
    }
    .summaryTitle {
      padding: 20px 250px !important;
    }
    .summaryText {
      padding: 20px 250px !important;
    }
  }
}
