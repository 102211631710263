.privacyPage {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .privacyContainer {
    text-align: left;
    display: flex;
    flex-direction: column;

    .privacyTitle {
      padding: 20px 40px;
      font-weight: 400;
      font-size: 28px;
      margin-bottom: 10px;
    }

    .privacyText {
      padding: 0 40px;
    }
  }

  .footer {
    margin-top: auto;
  }

  @media (min-width: 1000px) {
    .QR {
      width: 250px !important;
    }
    .privacyTitle {
      padding: 20px 250px !important;
    }
    .privacyText {
      padding: 20px 250px !important;
    }
  }
}
